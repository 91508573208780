// Fix conflit jQuery UI / Bootstrap
if (typeof $.ui !== 'undefined' && typeof _tooltip === 'function' && typeof _button === 'function') {

	$.fn.tooltip = _tooltip;
	$.fn.button = _button;
}

Object.defineProperty(Array.prototype, 'remove', {
  enumerable: false,
  value: function() {
      	var what, a = arguments, L = a.length, ax;
	    while (L && this.length) {
	        what = a[--L];
	        while ((ax = this.indexOf(what)) !== -1) {
	            this.splice(ax, 1);
	        }
	    }
	    return this;
    }
});

window.setCookie = function(key, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (1 * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

window.getCookie = function(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

window.sessionCheck = function() {
	$.ajax({
		url : cstPath.AJAX_REQUESTS + 'global_ajax.php',
		type : "POST",
		dataType : "json",
		data : {
			fuseaction : 'sessionCheck'
		},
		success : function(response) {
			if ( response.result <= 0 ) {
				swal({
					title : 'Attention',
					html : response.message + ', vous allez être redirigé<br />vers la page de connexion',
					type : 'error',
					confirmButtonColor : '#FC3',
					confirmButtonText : 'OK'
				}).then(function() {
					return self.location.reload();
				});
			}
		},
		error: function(error) {

			notifyError(error);
		}
	});
}

// Permet de gérer l'erreur 401 renvoyée par l'application si une requête AJAX ne peut pas aboutir
window.notifyError = function(error, title) {

    title = typeof title !== 'undefined' ? title : 'Erreur';

    if (error.status != 0) {

    	try {

	        var response = JSON.parse(error.responseText);

	        swal({
	            title: title,
	            html: response.message,
	            type: 'error',
	            confirmButtonColor: '#FC3'
	        }).then(function() {

	        	if (response.nextUrl) {

	        		return self.location = response.nextUrl;
	        	}
	        });
	    }
	    catch(e) {

	        swal({
	            title: title,
	            html: error.responseText || error.statusText,
	            type: 'error',
	            confirmButtonColor: '#FC3'
	        }).then(function() {

	            return self.location.reload();
	        });
	    }
    }
}

// Permet de gérer l'erreur si une requête AJAX retourne un résultat négatif
window.notifyFail = function(message, title, customClass, type) {

	message = typeof message !== 'undefined' ? message : 'Une erreur est survenue, veuillez réessayer';
	title = typeof title !== 'undefined' ? title : 'Erreur';
	customClass = typeof customClass !== 'undefined' ? customClass : '';
	type = typeof type !== 'undefined' ? type : 'error';

    swal({
    	title: title,
    	confirmButtonColor: '#FC3',
        html: message,
        type: type,
        customClass: customClass
    });
}

// Formatage de nombres avec une virgule
window.numberWithSpaces = function(number) {

    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

// Fix résultats filtrés sur datatables
window.editInfoMessage = function(start, end, max, total) {

    if (total == 0) {

    	if (total < max) {

    		var message = "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment";
    	}
    	else {

    		var message = "Aucun r&eacute;sultat";
    	}
    }
    else {

        var message = "Affichage de l'&eacute;l&eacute;ment " + numberWithSpaces(start) + " &agrave; " + numberWithSpaces(end) + " sur " + numberWithSpaces(total) + (total > 1 ? " &eacute;l&eacute;ments" : " &eacute;l&eacute;ment");
    }

    if (total < max) {

        message += " (filtr&eacute; de " + numberWithSpaces(max) + (max > 1 ? " &eacute;l&eacute;ments" : " &eacute;l&eacute;ment") + " au total)";
    }

    return message;
}

// Datatables
if ($.fn.dataTable) {

	var lengthMenu = [10, 15, 20, 50, 100];
	var length = Number((length = getCookie('dTablePageLength')) > 0 ? length : cstDatatable.PAGE_LENGTH);
	length = lengthMenu.findIndex(item => String(item) === String(length)) !== -1 ? length : lengthMenu[0];

	$.extend($.fn.dataTable.defaults, {
		'processing': true,
		'stateSave': true,
		'destroy': true,
		'serverSide': true,
		'autoWidth': false,
		'lengthMenu': [lengthMenu, lengthMenu],
		'pageLength': length,
		'language': {
			'url': cstPath.JS + '/ressources/fr_FR.txt'
		},
		'stateLoadParams': function(settings, data) {

			if (typeof settings.oInit.paging === 'undefined' || settings.oInit.paging) {

				var cookie = Number(getCookie('dTablePageLength'));

				if (cookie > 0) {

					var menu = Array.isArray(settings.oInit.aLengthMenu) && settings.oInit.aLengthMenu.length ? (Array.isArray(menu = settings.oInit.aLengthMenu[0]) ? menu : [menu]) : lengthMenu;
					data.length = menu.findIndex(item => String(item) === String(cookie)) !== -1 ? cookie : data.length;
				}

				data.start = typeof settings.oInit.resetPaging !== 'undefined' && settings.oInit.resetPaging ? 0 : data.start;
			}
		},
		'drawCallback': function(settings) {

			var table = $(this[0]);
			DTtitles(table);

			if (typeof settings.oInit.paging === 'undefined' || settings.oInit.paging) {

				var lengthElt = table.closest('.dataTables_wrapper').find('.dataTables_length select');

				if (lengthElt.length) {

					setCookie('dTablePageLength', lengthElt.val());
				}
			}
		},
	    'infoCallback': function(oSettings, iStart, iEnd, iMax, iTotal, sPre) {

	    	// Fix si tooltip visible au moment du rechargement d'une datatable.
	    	$('.tooltip').remove();

			return editInfoMessage(iStart, iEnd, iMax, iTotal);
		}
	});
}

if ($.validator) {
	jQuery.validator.setDefaults({
		errorClass: 'has-error',
	  	errorPlacement: function(error, element) {
	  		if (error.html()) {
	  			element.closest('.form-group').append(error);
	  		}		        
  		}
	});
}

// NAV FIXE
$('#myAffix').affix({
	offset: {
		top: 100,
		bottom: function () {
			return (this.bottom = $('.footer').outerHeight(true))
		}
	}
});

/////////////////////////////////////
// HAMBURGER
window.forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};
var hamburgers = document.querySelectorAll(".hamburger");
if (hamburgers.length > 0) {
	forEach(hamburgers, function(hamburger) {
		hamburger.addEventListener("click", function() {
			this.classList.toggle("is-active");
		}, false);
	});
}

// bootstrap datetimepicker
if ($.fn.datetimepicker) {
	$.fn.datetimepicker.defaults.locale = 'fr';
	$.fn.datetimepicker.defaults.tooltips = {
	    today: 'Aujourd\'hui',
	    clear: 'Effacer',
	    close: 'Fermer',
	    selectMonth: 'Sélectionnez un mois',
	    prevMonth: 'Mois précédent',
	    nextMonth: 'Mois suivant',
	    selectYear: 'Sélectionnez une année',
	    prevYear: 'Année précédente',
	    nextYear: 'Année suivante',
	    selectDecade: 'Sélectionner une décennie',
	    prevDecade: 'Décennie précédente',
	    nextDecade: 'Décennie suivante',
	    prevCentury: 'Siècle précédent',
	    nextCentury: 'Siècle suivant',
	    pickHour: 'Choisir une heure',
	    incrementHour: 'Incrémenter l\'heure',
	    decrementHour:'Décrémenter l\'heure',
	    pickMinute: 'Choisir une minute',
	    incrementMinute: 'Incrémenter les minutes',
	    decrementMinute:'Décrémenter les minutes',
	    pickSecond: 'Choisir une seconde',
	    incrementSecond: 'Incrémenter les secondes',
	    decrementSecond:'Décrémenter les secondes',
	    togglePeriod: 'Changer la période',
		selectTime: 'Sélectionner l\'heure'
	};
}

/////////////////////////////////////
// Btn Material Ripple effect
$("body").on( 'click', '.btn', function(event) {
	var surface = $(this);

	// create .material-ink element if doesn't exist
	if (surface.find(".material-ink").length == 0) {
		surface.prepend("<div class='material-ink'></div>");
	}

	var ink = surface.find(".material-ink");

	// in case of quick double clicks stop the previous animation
	ink.removeClass("animate");

	// set size of .ink
	if (!ink.height() && !ink.width()) {
		// use surface's width or height whichever is larger for
		// the diameter to make a circle which can cover the entire element
		var diameter = Math.max(surface.outerWidth(), surface.outerHeight());
		ink.css({height: diameter, width: diameter});
	}

	// get click coordinates
	// Logic:
	// click coordinates relative to page minus
	// surface's position relative to page minus
	// half of self height/width to make it controllable from the center
	var xPos = event.pageX - surface.offset().left - (ink.width() / 2);
	var yPos = event.pageY - surface.offset().top - (ink.height() / 2);

	var rippleColor = surface.data("ripple-color");

	//set the position and add class .animate
	ink.css({
		top: yPos + 'px',
		left: xPos + 'px',
		background: rippleColor
	}).addClass("animate");
});

window.renderRowsEq = function() {

	$('.row-eq-height').each(function() {

		var elements = $(this).find('> .col-eq-height, > .bg-grey');
		var filteredPanels = elements.has('> .panel > .panel-body > .tab-content > .tab-pane').find('> .panel');
		var listGroups = elements.find('> .panel > .list-group');
		listGroups.css('max-height', 0);

		if (filteredPanels.length) {

			filteredPanels.each(function() {

				var panel = $(this);
				var tabs = panel.find('> .panel-body > .tab-content > .tab-pane');
				panel.css('min-height', '');

				var minHeight = Math.max.apply(null, tabs.map(function() {

					tabs.css('display', 'none');
					$(this).css('display', 'block');

					return panel.outerHeight();
				})
				.get());

				tabs.css('display', '');
				panel.css('min-height', minHeight);
			});
		}

		if (listGroups.length) {

			filteredPanels = elements.has('> .panel > .panel-body form').find('> .panel');
			var maxHeight = 0;

			if (filteredPanels.length) {

				maxHeight = Math.max.apply(null, filteredPanels.map(function() {

					    return $(this).outerHeight();
					})
					.get());
			}

			listGroups.css('max-height', '');

			if (maxHeight) {

				listGroups.find('> .list-group-item:not(.hidden):gt(0)').addClass('d-none rendering-rows-eq');

				var maxListHeight = Math.max.apply(null, listGroups.map(function() {

					    return $(this).parent().outerHeight();
					})
					.get());

				maxHeight = maxListHeight > maxHeight ? maxListHeight : maxHeight;
			}
			else {

				listGroups.find('> .list-group-item:not(.hidden):gt(2)').addClass('d-none rendering-rows-eq');
			}

			listGroups.each(function() {

				var panel = $(this).parent();
				$(this).css('max-height', (maxHeight ? maxHeight : panel.outerHeight()) - panel.find('> .panel-heading').outerHeight());
				$(this).find('.rendering-rows-eq.d-none').removeClass('d-none rendering-rows-eq');
			});
		}
	});
}

window.renderPresentationTab = function() {

	if ($('#menu-presentations').length) {

		var element = $($('#menu-presentations a[aria-expanded="true"]').attr('href'));

		if (element.find('.list-group-item').length > 3) {

			var currentHeight = 0;

			for (i = 0; i < 3; i++) {

				var item = element.find('.list-group-item').eq(i);
				currentHeight = currentHeight + item.outerHeight() - (item.outerHeight() - item.outerHeight(true));
			}

			element.find('.list-group').addClass('scrolled').css('max-height', currentHeight);
		}
	}
};

window.toggleEllipsisTooltips = () => {

	$('.ellipsis-tooltip').each((index, element) => {

		let jqElement = $(element);

		if (element.scrollWidth > element.clientWidth) {

			jqElement.attr('data-toggle', 'tooltip');
		}
		else {

			jqElement.removeAttr('data-toggle').tooltip('hide');

			if (!element.hasAttribute('data-original-title')) {

				jqElement.attr('data-original-title', jqElement.attr('title')).attr('title', '');
			}
		}
	});
};

// On refait un rendu des rows et des listes après un resize
var rtime;
var timeout = false;
var delta = 200;
var loaded = 0;
window.resizeend = function(rtime) {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        resetDropdown();
        renderRowsEq();
        positionTooltip();
        resetDropdown(1);
        renderPresentationTab();
        toggleEllipsisTooltips();
    }               
}

var rtimeScroll;
var timeoutScroll = false;
var deltaScroll = 50;
window.scrollEnd = function() {
    if (new Date() - rtimeScroll < deltaScroll) {
        setTimeout(scrollEnd, deltaScroll);
    } else {
        timeoutScroll = false;
        positionTooltip();
    }               
};

$.fn.isOnScreen = function(height) {
	height = typeof height !== 'undefined' ? height : 0;
    var win = $(window);
    var bounds = this.offset();
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };

    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    
    bounds.top = bounds.top + height;
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (viewport.right >= bounds.right && viewport.left <= bounds.left && viewport.bottom >= bounds.bottom && viewport.top <= bounds.top);
};

window.positionTooltip = function() {
	var element = $('.tooltip');

	if (element.length) {

		if (element.hasClass('top') || element.hasClass('bottom')) {

			if (!element.isOnScreen() || element.hasClass('bottom')) {
				var height = element.outerHeight() + element.parent().find('[data-toggle="tooltip"]').outerHeight();
				var oldClass = 'top';
				var newClass = 'bottom';

				if (element.hasClass('bottom')) {
					height = -Math.abs(height);
					oldClass = 'bottom';
					newClass = 'top';
				}

				if (element.isOnScreen(height)) {
					var top = parseInt(element.css('top'));

					element.css('top', top + height + 'px');
					element.removeClass(oldClass);
					element.addClass(newClass);
				}
			}
		}
		else {

			if (!element.isOnScreen() || element.hasClass('right')) {
				var width = element.outerWidth() + element.parent().find('[data-toggle="tooltip"]').outerWidth();
				var oldClass = 'left';
				var newClass = 'right';

				if (element.hasClass('right')) {
					width = -Math.abs(width);
					oldClass = 'right';
					newClass = 'left';
				}

				if (element.isOnScreen(width)) {
					var left = parseInt(element.css('left'));

					element.css('left', left + width + 'px');
					element.removeClass(oldClass);
					element.addClass(newClass);
				}
			}
		}

		element.addClass('tooltip-visible');
	}
};

// Visualisation des demandes de mouvement en lien avec des présentations (dashboard)
window.resetDropdown = function(show) {
	show = typeof show !== 'undefined' ? show : 0;
	if ($('#mouvement_presentation').length) {
		var group = $('#mouvement_presentation .list-group');
		var panel = $('#mouvement_presentation');

		if (show) {
			var elements = $('#mouvement_presentation .hidden-dropdown');
			var groupHeight = group.outerHeight();
			var panelHeight = panel.outerHeight();
			var elementsHeight = 0;

			if (elements.length) {

				elements.removeClass('hidden-dropdown hidden');

				for (i = 0; i < elements.length; i++) {
					var element = elements.eq(i);
					elementsHeight = elementsHeight + element.outerHeight() - (element.outerHeight() - element.outerHeight(true));
				}

				group.addClass('scrolled');
			}

			group.css({
				'max-height': groupHeight + elementsHeight
			});
			panel.css({
				'height': panelHeight + elementsHeight
			});
		}
		else {
			$('#mouvement_presentation .shown').addClass('hidden-dropdown hidden');

			group.css({
				'max-height': ''
			});
			panel.css({
				'height': 'auto'
			});
		}
	}
};

window.disableTooltips = function() {
	$('[data-toggle="tooltip"]').tooltip('disable');
	$('.tooltip').remove();
};

window.enableTooltips = function() {
	$('.tooltip').remove();
	$('[data-toggle="tooltip"]').tooltip('enable');
};

// Bloque le bouton d'envoi
window.lockBtn = function(elements, loader) {

	loader = typeof loader !== 'undefined' ? loader : false;

	elements.filter(':focus').blur();

    elements.each(function() {

    	$(this).addClass('disabled');

        if ($(this).is('a, area, link, button, input, select, textarea, optgroup, option, fieldset')) {

            $(this).attr('disabled', true);
        }

        if (loader) {

			$(this).attr('data-loading-text', $(this).html()).html('<i class="material-icons">&#xE863;</i>');
        }
    });
}

// Débloque le bouton d'envoi
window.unlockBtn = function(elements, loader) {

    loader = typeof loader !== 'undefined' ? loader : false;

    elements.each(function() {

        if (loader) {

			$(this).html($(this).attr('data-loading-text'));
        }

        if ($(this).is('a, area, link, button, input, select, textarea, optgroup, option, fieldset')) {
            
            $(this).attr('disabled', false);
        }

        $(this).removeClass('disabled');
    });
}

/**
 * Recharge les options du <select> de zones à partir d'un lieu id
 * @param lieu
 * @param zone
 * @param emplacement
 * @param isCreationEmplacement
 * @param keepZone
 * @param keepEmplacement
 */
window.reloadZoneFromLieu = function(lieu, zone, emplacement, isCreationEmplacement, keepZone, keepEmplacement) {

	lieu = lieu instanceof $ ? lieu : $(lieu);
	zone = zone instanceof $ ? zone : $(zone);
	emplacement = typeof emplacement !== 'undefined' ? emplacement : null;
	isCreationEmplacement = typeof isCreationEmplacement !== 'undefined' ? isCreationEmplacement : 0;
	keepZone = typeof keepZone !== 'undefined' ? keepZone : false;
	keepEmplacement = typeof keepEmplacement !== 'undefined' ? keepEmplacement : false;

	$.ajax({
		url: cstPath.AJAX_REQUESTS + 'global_ajax.php',
		type: 'POST',
		dataType: 'html',
		data: {
			fuseaction: 'reloadZoneFromLieu',
			lieu_id: lieu.val(),
			isCreationEmplacement: isCreationEmplacement
		},
		success: function(response) {

			var value = keepZone ? zone.val() : null;
			zone.html(response);

			if (value !== null && zone.find('option[value="' + value + '"]').length) {

				zone.val(value);
			}
			else {

				var options = zone.find('option');

				if (options.length == 2 && options.eq(0).val() <= 0) {

					zone.val(options.eq(1).val());
				}
			}
			
			zone.select2({
					dropdownAutoWidth : true,
					width: '100%'	
				});

			if (emplacement) {

				reloadEmplacementFromLieuAndZone(lieu, zone.val(), emplacement, keepEmplacement);
			}
		},
		error: function(error) {

			notifyError(error);
		}
	});
}

/**
 * Recharge les options du <select> de d'emplacements à partir d'un lieu id et/ou d'une zone id
 * @param lieu
 * @param zoneId
 * @param emplacement
 * @param keepEmplacement
 */
window.reloadEmplacementFromLieuAndZone = function(lieu, zoneId, emplacement, keepEmplacement) {

	lieu = lieu instanceof $ ? lieu : $(lieu);
	emplacement = emplacement instanceof $ ? emplacement : $(emplacement);
	keepEmplacement = typeof keepEmplacement !== 'undefined' ? keepEmplacement : false;

	$.ajax({
		url: cstPath.AJAX_REQUESTS + 'global_ajax.php',
		type: 'POST',
		dataType: 'json',
		data: {
			fuseaction: 'reloadEmplacementFromLieuAndZone',
			lieu_id: lieu.val(),
			zone_id: zoneId
		},
		success: function(response) {

			var value = keepEmplacement ? emplacement.val() : null;
			emplacement.html(response.html);

			if (value !== null && emplacement.find('option[value="' + value + '"]').length) {

				emplacement.val(value);
			}
			else {

				var options = emplacement.find('option');

				if (options.length == 2 && options.eq(0).val() <= 0) {

					emplacement.val(options.eq(1).val());
				}
			}

			emplacement.select2({
					dropdownAutoWidth: true,
					width: '100%'	
				});

			lieu.val(response.lieu_id).trigger('change.select2');
		},
		error: function(error) {

			notifyError(error);
		}
	});
}

/**
 * Change les valeurs du lieu et de la zone liés à un emplacement.
 * @param lieu
 * @param zone
 * @param emplacement
 */
window.reloadLieuAndZoneFromEmplacement = function(lieu, zone, emplacement) {

	emplacement = emplacement instanceof $ ? emplacement : $(emplacement);
	var emplacementId = emplacement.val();
	lieu = lieu instanceof $ ? lieu : $(lieu);
	zone = zone instanceof $ ? zone : $(zone);

	if (emplacementId > 0 && (lieu.val() <= 0 || zone.val() <= 0)) {

		$.ajax({
			url: cstPath.AJAX_REQUESTS + 'global_ajax.php',
			type: 'POST',
			dataType: 'json',
			data: {
				fuseaction: 'reloadLieuAndZoneFromEmplacement',
				emplacement_id: emplacementId
			},
			success: function(response) {

				lieu.val(response.lieu_id).trigger('change.select2');
				zone.val(response.zone_id).trigger('change.select2');
				reloadZoneFromLieu(lieu, zone, emplacement, 0, true, true);
			},
			error: function(error) {

				notifyError(error);
			}
		});
	}
}

/**
 * Change la valeur du lieu lié à une zone.
 * @param lieu
 * @param zoneId
 */
window.reloadLieuFromZone = function(lieu, zoneId) {

	lieu = lieu instanceof $ ? lieu : $(lieu);

	if (zoneId > 0 && lieu.val() <= 0) {

		$.ajax({
			url: cstPath.AJAX_REQUESTS + 'global_ajax.php',
			type: 'POST',
			dataType: 'text',
			data: {
				fuseaction: 'reloadLieuFromZone',
				zone_id: zoneId
			},
			success: function(response) {

				lieu.val(response).trigger('change.select2');
			},
			error: function(error) {

				notifyError(error);
			}
		});
	}
}

// Initialisation de champs de dates de début et de fin.
window.initDates = function(dateDebutElt, dateFinElt, elementId, updateCallback) {

	dateDebutElt = dateDebutElt instanceof $ ? dateDebutElt : $(dateDebutElt);
	dateFinElt = dateFinElt instanceof $ ? dateFinElt : (typeof dateFinElt === 'string' ? $(dateFinElt) : dateFinElt);
	var dates = [dateDebutElt];
	let minDate = (minDate = dateDebutElt.attr('data-min-date')) && (minDate = moment(minDate, 'DD/MM/YYYY HH:mm')) && minDate.isValid() ? minDate : moment().millisecond(0).second(0);

	if (dateFinElt) {

		dates.push(dateFinElt);
	}

	var parametres = {
		minDate: minDate
	};

	if (elementId > 0) {

		parametres.useCurrent = false;
		var dateDebutVal = dateDebutElt.val();

		if (dateDebutVal) {

			var dateDebut = moment(dateDebutVal, 'DD/MM/YYYY HH:mm');

			if (dateDebut.isBefore(minDate)) {

				parametres.minDate = dateDebut;
			}
		}
	}

	$.each(dates, function() {

		parametres.widgetParent = (container = $(this).closest('.datetimepicker-fix')) && container.length ? container : $(this).closest('.form-group');

		$(this).datetimepicker(parametres)
		.on('dp.change', function(event) {

			if ((!parametres.useCurrent && typeof parametres.useCurrent !== 'undefined') || event.oldDate) {

				updateDates(event, dateDebutElt, dateFinElt, elementId, updateCallback);
			}
		})
		.on('dp.error', function(event) {

			if (event.date.isValid()) {

				updateDates(event, dateDebutElt, dateFinElt, elementId, updateCallback);
			}
			else {

				$(event.currentTarget).data('DateTimePicker').clear();
			}
		});
	});
}

// Mise à jour de la valeur minimum de champs de dates de début et de fin suivi d'un callback.
window.updateDates = function(event, dateDebutElt, dateFinElt, elementId, updateCallback) {

	let minDate = (minDate = dateDebutElt.attr('data-min-date')) && (minDate = moment(minDate, 'DD/MM/YYYY HH:mm')) && minDate.isValid() ? minDate : moment().millisecond(0).second(0);
	var isDateDebut = event.currentTarget == dateDebutElt[0];
	var dataDateDebut = dateDebutElt.data('DateTimePicker');
	var dataDateFin = dateFinElt ? dateFinElt.data('DateTimePicker') : null;
	var dateDebut = oldDateDebut = isDateDebut ? event.date : dataDateDebut.date();
	var dateFin = dateFinElt ? (oldDateFin = !isDateDebut ? event.date : dataDateFin.date()) : null;
	var update = false;

	if (dateDebut && dateDebut.isBefore(minDate)) {

		dateDebut = minDate;
	}

	if (dateFin && dateFin.isBefore(minDate)) {

		dateFin = minDate;
	}

	if (dateDebut && dateFin) {

		if (isDateDebut && dateFin.isBefore(dateDebut)) {

			dateFin = dateDebut;
		}
		else if (!isDateDebut && dateDebut.isAfter(dateFin)) {

			dateDebut = dateFin;
		}
	}

	if (dateDebut && !dateDebut.isSame(oldDateDebut)) {

		dataDateDebut.date(dateDebut);
		update = true;
	}

	if (!dataDateDebut.minDate().isSame(minDate)) {

		dataDateDebut.minDate(minDate);
	}

	if (dateFin && !dateFin.isSame(oldDateFin)) {

		dataDateFin.date(dateFin);
		update = true;
	}

	if (dateFinElt && !dataDateFin.minDate().isSame(minDate)) {

		dataDateFin.minDate(minDate);
	}

	if (typeof updateCallback === 'function' && elementId > 0 && event.namespace === 'change' && !update) {

		updateCallback();
	}
}

// Titres datatable responsive
window.DTtitles = function(table) {

	table = table instanceof $ ? table : $(table);

	table.find('thead tr th').each(function(index) {

	    table.find('tr td:not(.dataTables_empty):nth-child(' + (index + 1) + ')').attr('data-title', $(this).text());
	});
}

// Réinitialisation des datatables au changement de page / onglet
window.DTredirect = function(tables, callback) {

	tables = Array.isArray(tables) ? tables : [tables];

	tables.forEach(function(table, index) {

		this[index] = table instanceof $ ? table : $(table);

	}, tables);

	$('a:not([href^="#"])').on('click', function(e) {

		e.preventDefault();
			
		if (!$(this).hasClass('btn-icon')) {

			if (typeof callback === 'function') {

				callback();
			}

			tables.forEach(function(table) {

				var dt = table.DataTable();
				var paging = dt.settings()[0].oInit.paging;

				if (typeof paging === 'undefined' || paging) {

					dt.page.len(Number((length = getCookie('dTablePageLength')) > 0 ? length : cstDatatable.PAGE_LENGTH));
				}

				dt.search('').draw();
			});
		}

		ajaxRedirect($(this).attr('href'));
	});
}

window.ajaxRedirect = function(url, timeout) {

	timeout = typeof timeout !== 'undefined' ? timeout : 50;

	if ($.active) {

		setTimeout(function() {

			ajaxRedirect(url);

		}, timeout);
	}
	else {

		url ? window.location.href = url : window.location.reload();
	}
}

// PANEL FOOTER TO THE BOTTOM FOR ADMINISTRATION

$(document).ready(function() {

	// File input

	$('.file-input').on('change', () => {

		let wrapper = $(event.currentTarget).closest('.input-group');

		let name = wrapper.find('.file-name');

		if (!name.attr('data-value')) {

			name.attr('data-value', name.val());
		}

		name.val(event.currentTarget.files[0].name);

		let title = wrapper.find('.file-title');
		let titleText = title.text();

		wrapper.find('.file-clear').removeClass('d-none');

		title.text(title.attr('data-title')).attr('data-title', titleText); 
    });

	$('.file-clear').on('click', (event) => {

		let element = $(event.currentTarget);
		let wrapper = element.closest('.input-group');

		let name = wrapper.find('.file-name');

		let title = wrapper.find('.file-title');
		let titleText = title.text();

		element.addClass('d-none');

		name.val(name.attr('data-value'));

		wrapper.find('.file-input').val('');
		
		title.text(title.attr('data-title')).attr('data-title', titleText);
	});

	// On vérifie que l'utilisateur est connecté toutes les 30 secondes
	if ($('body').data('utilisateurId')) {
		window.setInterval(sessionCheck, 30000);
	}

	// Floating Labels
	//==============================================================
	$('body').on('keyup input change dp.change', '[data-toggle="floatLabel"]', function() {
		var val = $(this).val();
		val = val != null ? val : '';
		$(this).attr('data-value', val);
	});

	$('[data-toggle="floatLabel"]').each( function() {
		var val = $(this).val();
		val = val != null ? val : '';
		$(this).attr('data-value', val);
	});

	// Fix select2 safari
	$('body').on('select2:open', 'select', function() {

		if ($(this).attr('data-opened') == 1) {

			return;
		}

		$(this).attr('data-opened', 1);

		$(this).select2('close');
		$(this).select2('open');
	});
});

$(window).resize(function() {
	$(".fixed").css({
		position : "absolute"
	});

	if (loaded == 1) {
		rtime = new Date();
	    if (timeout === false) {
	        timeout = true;
	        setTimeout(resizeend, delta);
	    }
	}
});

$(window).scroll(function() {
    rtimeScroll = new Date();
    if (timeoutScroll === false) {
        timeoutScroll = true;
        setTimeout(scrollEnd, deltaScroll);
    }
});

$(window).on('load', function() {
	// au chargement complet de la page, la fonction resize() est appelée une fois pour initialiser le centrage.
	$(window).resize();

	renderRowsEq();
	resetDropdown(1);
	renderPresentationTab();
	toggleEllipsisTooltips();

	loaded = 1;
});

/*menu handler*/
$(function(){
	var url = window.location.pathname;  
	var activePage = url.substring(url.lastIndexOf('/')+1);
	$('.nav li a').each(function(){  
		var currentPage = this.href.substring(this.href.lastIndexOf('/')+1);
		if (activePage == currentPage) {
			$(this).parent().addClass('active'); 
		}
	});
});


$(document).on('click', '#close-preview', function(){ 
	$('.image-preview').popover('hide');
	// Hover befor close the preview
	$('.image-preview').hover(
		function () {
			$('.image-preview').popover('show');
		},
		function () {
			$('.image-preview').popover('hide');
		}
	);   
});


/* /////
Input File - Popover Preview Image
////// */
$(function() {
	// Create the close button
	var closebtn = $('<button/>', {
		type:"button",
		text: 'x',
		id: 'close-preview',
		style: 'font-size: initial;',
	});

	closebtn.attr("class","close pull-right");

	// Set the popover default content
	$('.image-preview').popover({
		trigger:'manual',
		html:true,
		title: "<strong>Aperçu</strong>"+$(closebtn)[0].outerHTML,
		content: "Il n'y a pas d'image",
		placement:'bottom'
	});
	
	// Clear event
	$('.image-preview-clear').click(function() {

		$('.image-preview').attr("data-content","").popover('hide');
		
		let name = $('.image-preview-filename');

		name.val(name.attr('data-value'));

		$('.image-preview-clear').hide();
		$('.image-preview-input input:file').val("");
		$(".image-preview-input-title").text("Parcourir"); 
	});


	// Create the preview image
	$(".image-preview-input input:file").change(function (){     
		var img = $('<img/>', {
			id: 'dynamic',
			width:250,
			height:200
		});

		var file = this.files[0];
		var reader = new FileReader();

		// Set preview image into the popover data-content
		reader.onload = function (e) {

			$(".image-preview-input-title").text("Changement");
			$(".image-preview-clear").show();

			let name = $('.image-preview-filename');

			if (!name.attr('data-value')) {

				name.attr('data-value', name.val());
			} 

			name.val(file.name);

			img.attr('src', e.target.result);
			$(".image-preview").attr("data-content",$(img)[0].outerHTML).popover("show");
		}

		reader.readAsDataURL(file);
    });  
});

// Ajout d'une classe pour gérer la taille du tooltip, calcul des dimensions, positionnement
$(function() {

	if (typeof $.fn.tooltip.Constructor === 'undefined') {
		throw new Error('Bootstrap Tooltip is not included');
	}

	var Tooltip = $.fn.tooltip.Constructor;
	var _init = Tooltip.prototype.init;

	// Ajout d'une classe pour gérer la taille du tooltip
	Tooltip.prototype.init = function () {
		_init.apply(this, Array.prototype.slice.apply(arguments));

		var dataSize = this.$element.data('size');
		if (dataSize) {
			this.options.template = '<div class="tooltip tooltip-' + dataSize +'" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
		}
	};

	// Calcul des dimensions du tooltip, positionnement
	Tooltip.prototype.show = function () {
        var e = $.Event('show.bs.' + this.type)

		if (this.hasContent() && this.enabled) {
			this.$element.trigger(e)

			var inDom = $.contains(this.$element[0].ownerDocument.documentElement, this.$element[0])
			if (e.isDefaultPrevented() || !inDom) return
			var that = this

			var $tip = this.tip()

			// Réinitialisation des dimensions du tooltip
			$tip.css('width', '');
			$tip.css('height', '');

			var tipId = this.getUID(this.type)

			this.setContent()
			$tip.attr('id', tipId)
			this.$element.attr('aria-describedby', tipId)

			if (this.options.animation) $tip.addClass('fade')

			var placement = typeof this.options.placement == 'function' ?
				this.options.placement.call(this, $tip[0], this.$element[0]) :
				this.options.placement

			var autoToken = /\s?auto?\s?/i
			var autoPlace = autoToken.test(placement)
			if (autoPlace) placement = placement.replace(autoToken, '') || 'top'

			$tip
				.detach()
				.css({ top: 0, left: 0, display: 'block' })
				.addClass(placement)
				.data('bs.' + this.type, this)

			this.options.container ? $tip.appendTo(this.options.container) : $tip.insertAfter(this.$element)
			this.$element.trigger('inserted.bs.' + this.type)

			var pos = this.getPosition()
			var actualWidth	= $tip[0].offsetWidth
			var actualHeight = $tip[0].offsetHeight

			// Position du viewport en rapport au placement
			var viewportDim = this.getPosition(this.$viewport, placement);

			if (autoPlace) {
				var orgPlacement = placement

				placement = placement == 'bottom' && pos.bottom + actualHeight > viewportDim.bottom ? 'top'		:
										placement == 'top'		&& pos.top		- actualHeight < viewportDim.top		? 'bottom' :
										placement == 'right'	&& pos.right	+ actualWidth	> viewportDim.width	? 'left'	 :
										placement == 'left'	 && pos.left	 - actualWidth	< viewportDim.left	 ? 'right'	:
										placement

				$tip
					.removeClass(orgPlacement)
					.addClass(placement)
			}

			var calculatedOffset = this.getCalculatedOffset(placement, pos, actualWidth, actualHeight)

			// Calcul des dimensions du tooltip
			if (viewportDim.tipWidth) {
				var tipWidth = viewportDim.tipWidth;
				var tipHeight = viewportDim.tipHeight;

				var elementWidth = viewportDim.elementWidth;
				var elementHeight = viewportDim.elementHeight;
			}
			else {
				var tipDimensions = this.$tip[0].getBoundingClientRect();
				var tipWidth = tipDimensions.width ? tipDimensions.width : tipDimensions.right - tipDimensions.left;
				var tipHeight = tipDimensions.height ? tipDimensions.height : tipDimensions.bottom - tipDimensions.top;

				var elementDimensions = this.$element[0].getBoundingClientRect();
				var elementWidth = elementDimensions.width ? elementDimensions.width : elementDimensions.right - elementDimensions.left;
				var elementHeight = elementDimensions.height ? elementDimensions.height : elementDimensions.bottom - elementDimensions.top;
			}

			// Initialisation des dimensions du tooltip avant le placement
			$tip.css('width', tipWidth + 'px');
			$tip.css('height', tipHeight + 'px');

			this.applyPlacement(calculatedOffset, placement)

			// Positionnement de la flèche 
			if (placement == 'top' || placement == 'bottom') {
				var tipCenterLeft = this.$element.offset().left - this.$tip.offset().left + elementWidth / 2;
				this.arrow().css('left', tipCenterLeft / tipWidth * 100 + '%');
			}
			else {
				var tipCenterTop = this.$element.offset().top - this.$tip.offset().top + elementHeight / 2;
				this.arrow().css('top', tipCenterTop / tipHeight * 100 + '%');
			}

			var complete = function () {
				var prevHoverState = that.hoverState
				that.$element.trigger('shown.bs.' + that.type)
				that.hoverState = null

				if (prevHoverState == 'out') that.leave(that)
			}

			$.support.transition && this.$tip.hasClass('fade') ?
				$tip
					.one('bsTransitionEnd', complete)
					.emulateTransitionEnd(Tooltip.TRANSITION_DURATION) :
				complete()
		} 
	}

	// Calcul de la position du tooltip
	Tooltip.prototype.getPosition = function ($element, placement) {
	    $element 	= $element || this.$element;
	    placement 	= placement || this.options.placement;
	    placement 	= typeof placement == 'function' ? placement.call(this, this.$tip[0], this.$element[0]) : placement;

	    var el     = $element[0]
	    var isBody = el.tagName == 'BODY'

	    var elRect    = el.getBoundingClientRect()
	    if (elRect.width == null) {
	      // width and height are missing in IE8, so compute them manually; see https://github.com/twbs/bootstrap/issues/14093
	      elRect = $.extend({}, elRect, { width: elRect.right - elRect.left, height: elRect.bottom - elRect.top })
	    }

	    // Position du viewport
	    var elOffset  = isBody ? { top: $(window).scrollTop(), left: $(window).scrollLeft() } : $element.offset()
	    var scroll    = { 
	    	scroll: isBody ? document.documentElement.scrollTop || document.body.scrollTop : $element.scrollTop(),
	    	scrollLeft: isBody ? document.documentElement.scrollLeft || document.body.scrollLeft : null
	    }
	    var outerDims = isBody ? { 
	    	width: 
	    		$(window).width() - 
	    		( this.$viewport[0].style.paddingRight ? parseInt(this.$viewport[0].style.paddingRight) : 0 ) -
	    		( this.$viewport[0].style.paddingLeft ? parseInt(this.$viewport[0].style.paddingLeft) : 0 ), 
	    	height: 
	    		$(window).height() - 
	    		( this.$viewport[0].style.paddingBottom ? parseInt(this.$viewport[0].style.paddingBottom) : 0 ) - 
	    		( this.$viewport[0].style.paddingTop ? parseInt(this.$viewport[0].style.paddingTop) : 0 )
	    } : null;
	    var tipSize = isBody ? {} : null;
	    var elementSize = isBody ? {} : null;

	    if (isBody) {

	    	var elementOffset = this.$element.offset();
	    	var elementDimensions = this.$element[0].getBoundingClientRect();
			elementSize.elementWidth = elementDimensions.width ? elementDimensions.width : elementDimensions.right - elementDimensions.left;
			elementSize.elementHeight = elementDimensions.height ? elementDimensions.height : elementDimensions.bottom - elementDimensions.top;

			var tipDimensions = tipDimensions ? tipDimensions : this.$tip[0].getBoundingClientRect();
			tipSize.tipWidth = tipDimensions.width ? tipDimensions.width : tipDimensions.right - tipDimensions.left;
			tipSize.tipWidth = Math.ceil(tipSize.tipWidth);
			tipSize.tipHeight = tipDimensions.height ? tipDimensions.height : tipDimensions.bottom - tipDimensions.top;
			tipSize.tipHeight = Math.ceil(tipSize.tipHeight);

			switch (placement) {

				case 'top' :
					var elementRight = elementOffset.left + elementSize.elementWidth / 2 + tipSize.tipWidth / 2 - scroll.scrollLeft;
					var elementBottom = elementOffset.top + elementSize.elementHeight - scroll.scroll;
				break;

				case 'bottom' :
					var elementRight = elementOffset.left + elementSize.elementWidth / 2 + tipSize.tipWidth / 2 - scroll.scrollLeft;
					var elementBottom = elementOffset.top + elementSize.elementHeight + tipSize.tipHeight - scroll.scroll;
				break;

				case 'left' :
					var elementRight = elementOffset.left + elementSize.elementWidth - scroll.scrollLeft;
					var elementBottom = elementOffset.top + elementSize.elementHeight / 2 + tipSize.tipHeight / 2 - scroll.scroll;
				break;

				case 'right' :
					var elementRight = elementOffset.left + elementSize.elementWidth + tipSize.tipWidth - scroll.scrollLeft;
					var elementBottom = elementOffset.top + elementSize.elementHeight / 2 + tipSize.tipHeight / 2 - scroll.scroll;
				break;
			}

			outerDims.width = elementRight > outerDims.width ? outerDims.width : elementRight;
			outerDims.height = elementBottom > outerDims.height ? outerDims.height : elementBottom;
	    }

	    return $.extend({}, elRect, scroll, outerDims, elOffset, tipSize, elementSize);
  	}

  	// Position du viewport
  	Tooltip.prototype.getViewportAdjustedDelta = function (placement, pos, actualWidth, actualHeight) {
	    var delta = { top: 0, left: 0 }
	    if (!this.$viewport) return delta

	    var viewportPadding = this.options.viewport && this.options.viewport.padding || 0;

		// Position du viewport en rapport au placement
	    var viewportDimensions = this.getPosition(this.$viewport, placement);

	    if (/right|left/.test(placement)) {
	      var topEdgeOffset    = pos.top - viewportPadding - viewportDimensions.scroll
	      var bottomEdgeOffset = pos.top + viewportPadding - viewportDimensions.scroll + actualHeight
	      if (topEdgeOffset < viewportDimensions.top) { // top overflow
	        	delta.top = viewportDimensions.top - topEdgeOffset
	      } 
	      else if (bottomEdgeOffset > viewportDimensions.top + viewportDimensions.height) { // bottom overflow
	        	delta.top = viewportDimensions.top + viewportDimensions.height - bottomEdgeOffset
	      }
	    } 
	    else {
		    var leftEdgeOffset  = pos.left - viewportPadding
		    var rightEdgeOffset = pos.left + viewportPadding + actualWidth
		    if (leftEdgeOffset < viewportDimensions.left) { // left overflow
		        delta.left = viewportDimensions.left - leftEdgeOffset
		    } else if (rightEdgeOffset > viewportDimensions.right) { // right overflow
		        delta.left = viewportDimensions.left + viewportDimensions.width - rightEdgeOffset
		    }
	    }

	    return delta
  	}
});

// Initialisation des Tooltips
$('body').tooltip({
  	selector: '[data-toggle="tooltip"]',
  	container: 'body'
});

// Permet aux tooltips des boutons de statut d'être correctement gérés
$(document).on('focus', '[data-toggle="tooltip"]', function(ev) {
	ev.preventDefault();
	$(this).blur();
});

$(document).on('shown.bs.tooltip', function() {
	positionTooltip();
});

$(document).on('hidden.bs.tooltip', function(e) {
	$(e.target).data('bs.tooltip').tip().removeClass('tooltip-visible');
});

$(document).ready(function() {

	// Switch order

    $('body').on('click', '.switch-order .btn-order', function (event) {

    	let element = $(event.currentTarget);
    	let table = element.closest('table');
    	let buttons = table.find('.btn-order');

    	lockBtn(buttons);

    	$.ajax({
			url: route('ajax.switch_order'),
			type: 'POST',
			dataType: 'json',
			data: {
				'type': table.attr('data-type'),
				'id': element.closest('.switch-order').attr('data-id'),
				'direction': element.attr('data-direction')
			}
		})
		.done((data) => {

			disableTooltips();
			table.DataTable().ajax.reload();
			enableTooltips();
		})
		.fail((data) => {

			notifyFail(data.responseJSON.message);
			unlockBtn(buttons);
		});
    });
});
